import React from 'react'

import Image from 'gatsby-image'

import { LinkCard as Card } from '../card'

import styles from './style.module.scss'

import { get } from 'lodash-es'

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

function sortByMonth(e1, e2) {
    const numberPattern = /\d+/g
    const yearOfe1 = e1.node.name.match(numberPattern).join('')
    const yearOfe2 = e2.node.name.match(numberPattern).join('')

    if (yearOfe1 !== yearOfe2) {
        if (+yearOfe1 < +yearOfe2) {
            return 1
        } else if (+yearOfe1 > +yearOfe2) {
            return -1
        } else {
            return 0
        }
    }

    let indexOfe1 = months.indexOf(
        months.find(m => e1.node.name.toLowerCase().match(m.toLowerCase()))
    )

    let indexOfe2 = months.indexOf(
        months.find(m => e2.node.name.toLowerCase().match(m.toLowerCase()))
    )

    if (indexOfe1 < indexOfe2) {
        return 1
    }

    if (indexOfe1 > indexOfe2) {
        return -1
    }

    return 0
}

export default ({ data }) => {
    return (
        <div className="row">
            <div className="col-xs-12">
                <h1>Property Mag</h1>
            </div>
            {data.allWordpressWpPropertyMag.edges
                .sort(sortByMonth)
                .map(edge => {
                    const fluid = get(
                        edge,
                        'node.acf.mag_image.localFile.childImageSharp.fluid'
                    )

                    return (
                        fluid && (
                            <div
                                className="col-xs-12 col-sm-6 col-md-4"
                                key={edge.node.name}
                            >
                                <Card
                                    className={styles.item}
                                    to={'/property-mag/' + edge.node.slug}
                                    titleClassName={styles.title}
                                    label={edge.node.name}
                                >
                                    <Image
                                        className={styles.image}
                                        fluid={get(
                                            edge,
                                            'node.acf.mag_image.localFile.childImageSharp.fluid'
                                        )}
                                    />
                                </Card>
                            </div>
                        )
                    )
                })}
        </div>
    )
}
